<template>
  <div>
    <h1>承認状況確認</h1>
    <v-radio-group
      v-model="select"
      row
      label="承認区分:"
      @change="getdata(false)"
    >
      <v-radio
        label="勤務表"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Attendance"
      ></v-radio>
      <!-- <v-radio
        label="休暇"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Holiday"
      ></v-radio>
      <v-radio
        label="シフト"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Shift"
      ></v-radio>
      <v-radio
        label="残業"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Overtime"
      ></v-radio>
      <v-radio
        label="実績"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Engraving"
      ></v-radio> -->
    </v-radio-group>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- 対象年月 -->
            <v-row>
              <v-col colspan="12">
                <DatePicker
                  v-model="date"
                  label="対象年月"
                  prepend-inner-icon="mdi-calendar"
                  type="month"
                  outlined
                  next
                  previous
                />
              </v-col>
            </v-row>
            <!-- 対象年月日From ~ To -->
            <!-- select == Selects.Holiday -->
            <v-row v-if="false">
              <v-col cols="5">
                <DatePicker
                  v-model="dateFrom"
                  label="対象年月From"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                />
              </v-col>
              <v-col cols="2"><p class="range-text">〜</p></v-col>
              <v-col cols="5">
                <DatePicker
                  v-model="dateTo"
                  label="対象年月To"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                />
              </v-col>
            </v-row>
            <!-- 所属選択 -->
            <v-row>
              <v-col colspan="12">
                <v-autocomplete
                  v-model="selectFilterAffiliations"
                  :items="filterAffiliations"
                  label="所属"
                  multiple
                  outlined
                  clearable
                  chips
                  item-text="name"
                  item-value="code"
                />
              </v-col>
            </v-row>
            <!--  -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getdata(true)">取得</Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5" v-if="items.length > 0">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <!-- <v-radio-group
          v-model="tableFilter"
          class="table-filter"
          row
          label="承認状態:"
          @change="changeTableFilter(tableFilter)"
        >
          <v-radio
            label="承認待ち"
            prepend-icon="mdi-toggle-switch"
            :value="Filters.Wait"
          ></v-radio>
          <v-radio
            label="未承認"
            prepend-icon="mdi-toggle-switch"
            :value="Filters.Unapproved"
          ></v-radio>
          <v-radio
            label="承認済み"
            prepend-icon="mdi-toggle-switch"
            :value="Filters.Done"
          ></v-radio>
        </v-radio-group> -->
      </v-card-title>
      <!--  -->
      <v-card-text>
        <v-row justify="start">
          <v-col>
            <Btn color="success" text outlined @click="add"> 承認 </Btn>
          </v-col>
          <!-- <v-col>
            <Btn color="error" text outlined @click="cancel"> 取消 </Btn>
          </v-col> -->
        </v-row>
      </v-card-text>
      <!--  -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :item-key="itemKey"
        :items-per-page="-1"
        multi-sort
        show-select
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <!-- <v-checkbox v-model="item.data-table-select" readonly /> -->
          <v-simple-checkbox
            :value="isSelected"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template
          v-if="select == Selects.Attendance"
          v-slot:item.actions="{ item }"
        >
          <Btn color="info" text small @click="details(item)">確認</Btn>
        </template>
      </v-data-table>
    </v-card>
    <p v-else>承認が必要な情報はありません</p>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { BUSYO_CODE as BusyoCode } from "@/defines";
import { CODE_GROUP as CodeGroup } from "@/defines";
import AttendanceFlowApprovalDat from "@/models/AttendanceFlowApprovalDat";

const DATE = new Date();

const Selects = {
  Attendance: 0,
  Holiday: 1,
  Shift: 2,
  Overtime: 3,
  Engraving: 4
};

const Filters = {
  Wait: 0,
  Unapproved: 1,
  Done: 2
};

export default {
  name: "ApprovalStatus",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      displayDate: "",
      panel: null,
      filterAffiliations: [],
      selectFilterAffiliations: [],
      // 承認状態
      Filters,
      tableFilter: Filters.Wait,
      selectDelete: "2",
      // 承認区分
      Selects,
      select: Selects.Attendance,
      // 事業所選択
      shozokus: [],
      shozoku: { code: "", name: "" },
      //
      disabledCount: 0
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async details(item) {
      console.log("details", item);
      const paymentDate = await this.getPaymentDate();
      const ymd = paymentDate.split("-");
      const param = {
        userincode: item.userIncode,
        targetdate: ymd[0] + "-" + ymd[1] + "-01",
        approvalStatus: "1",
        targetUsers: this.items,
        filter: {
          paymentDate: paymentDate
        }
      };
      let name = "Attendance";
      if (item.code1 === String(BusyoCode.WELFARE))
        name = "WelfareAttendanceDetails";

      this.$router.push({ name: name, params: param });
    },
    async getdata(api) {
      console.log("getdata");
      if (api) {
        this.$loading();
        try {
          // const selectData = {
          //   path: "",
          //   flg: false,
          // };
          // const params = {
          //   paymentDate: await this.getPaymentDate(),
          //   affiliationIds: this.selectFilterAffiliations,
          // };

          const reqest = {
            path: "",
            params: {}
          };
          switch (this.select) {
            case Selects.Attendance:
              reqest.path = this.Paths.approvalStatus;
              reqest.params = {
                paymentDate: await this.getPaymentDate(),
                affiliationIds: this.selectFilterAffiliations
              };
              break;
            case Selects.Holiday:
              break;
            case Selects.Shift:
              break;
            case Selects.Overtime:
              break;
            case Selects.Engraving:
              reqest.path = this.Paths.approvalAttendanceStatus;
              reqest.params = {
                fromDate: await this.getMonthFromDate(),
                toDate: await this.getMonthToDate(),
                affiliationIds: this.selectFilterAffiliations
              };
              console.log(
                await this.getMonthFromDate(),
                await this.getMonthToDate()
              );
              break;
          }

          console.log("params", reqest.params);
          const encode = encodeURI(JSON.stringify(reqest.params));
          const data = await this.$get(
            //this.Paths.approvalStatus,
            reqest.path,
            "query=" + encode
          );
          await this.setdata(data);
          this.displayDate = await this.getPaymentDate();
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      } else {
        console.log("aaa");
        this.$loading();
        try {
          await this.setdata({ headers: [] });
          this.displayDate = await this.getPaymentDate();
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async setdata(data) {
      console.log("setdata");
      if (data && data.headers.length > 0) {
        this.headers = data.headers;
        this.headers.push({ text: "actions", value: "actions" });
        this.items = data.items;
        this.title = data.name;
        this.itemKey = data.key;
      } else {
        this.items = [];
      }

      this.displayDate = "";
    },
    async getPaymentDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return result;
    },
    async getMonthFromDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      //dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() - 1)).slice(-2) +
        "-" +
        "21";

      return result;
    },
    async getMonthToDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]) + 1, 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth())).slice(-2) +
        "-" +
        "20";

      return result;
    },
    async add() {
      console.log("add");
      const message = [];
      message[0] =
        this.selected.length + "件 一括申請します。 \nよろしいですか？";
      const result = await this.$confirm(message[0]);

      if (result) {
        const ids = [];
        this.selected.forEach(s => {
          ids.push(s.recordId);
        });

        if (ids.length == 0) {
          this.$info("申請対象データが有りませんでした。", "勤務時間");
          return;
        }

        const reqest = {
          path: "",
          params: {}
        };
        switch (this.select) {
          case Selects.Attendance:
            break;
          case Selects.Holiday:
            break;
          case Selects.Shift:
            break;
          case Selects.Overtime:
            break;
          case Selects.Engraving:
            reqest.path = this.Paths.approvalAttendanceStatus;
            reqest.params = {};
            break;
        }

        this.$loading();

        try {
          // const param = ids.join(",");
          // const encode = encodeURI(param);

          const params = new AttendanceFlowApprovalDat(ids, "1");
          console.log(params);
          await this.$put(this.Paths.approvalAttendance, params);

          // console.log(this.rows);
          // const rows = this.rows;
          // const indexes = this.selected.map((select) => select.originalIndex);
          // this.rows = rows.filter((row, i) => !indexes.includes(i));

          await this.$info("承認しました。");
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
          await this.getdata(true);
        }
      }
    },
    async cancel() {
      console.log("cancel");
      const message = [];
      message[0] =
        this.selected.length + "件 申請を一括取消します。 \nよろしいですか？";
      const result = await this.$confirm(message[0]);

      if (result) {
        const ids = [];
        this.selected.forEach(s => {
          ids.push(s.recordId);
        });

        if (ids.length == 0) {
          this.$info("申請取消対象データが有りませんでした。");
          return;
        }

        const reqest = {
          path: "",
          params: {}
        };
        switch (this.select) {
          case Selects.Attendance:
            break;
          case Selects.Holiday:
            break;
          case Selects.Shift:
            break;
          case Selects.Overtime:
            break;
          case Selects.Engraving:
            reqest.path = this.Paths.approvalAttendanceStatus;
            reqest.params = {};
            break;
        }

        this.$loading();

        try {
          // const param = ids.join(",");
          // const encode = encodeURI(param);

          const params = new AttendanceFlowApprovalDat(ids, "0");
          console.log(params);
          await this.$put(this.Paths.approvalAttendance, params);

          // console.log(this.rows);
          // const rows = this.rows;
          // const indexes = this.selected.map((select) => select.originalIndex);
          // this.rows = rows.filter((row, i) => !indexes.includes(i));

          await this.$info("取消しました。");
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
          await this.getdata(true);
        }
      }
    }
  },
  async created() {
    console.log("==created");
    this.$loading();
    try {
      this.filterAffiliations = await this.$get(this.Paths.shozokuApproval);
      // this.filterAffiliations = await this.$get(this.Paths.shozokuApproval);
      this.date = this.$getsecurityehimeServiceDate();
      console.log(this.date);
      console.log("query", this.$route.query);
      if ("filter" in this.$route.query) {
        const param = this.$route.query.filter;
        if (typeof param !== "undefined" && Object.keys(param).length) {
          this.date = param.paymentDate;
        }
      }
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style scoped>
.v-data-table td {
  background: #f0f8ff;
}
.v-data-table tr:nth-child(odd) td {
  background: #fff;
}
.v-data-table tr:hover td {
  background-color: #eee;
}
</style>
